<template>
  <!-- Start Service Area  -->
  <v-row class="service-one-wrapper">
    <!-- Start Single Service  -->
    <v-col
      lg="6"
      md="6"
      sm="6"
      cols="12"
      v-for="(service, i) in serviceContent"
      :key="i"
    >
      <div class="single-service service__style--4">
        <router-link to="/service-details">
          <div class="service">
            <div class="icon" v-html="iconSvg(service.icon)"></div>
            <div class="content">
              <h3 class="heading-title">{{ service.title }}</h3>
              <p>
                {{ service.desc }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
  <!-- Start Service Area  -->
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        serviceContent: [
          {
            icon: "cast",
            title: "Tiles",
            desc: ` The possibilities are endless with tile.  Available in ceramic, porcelain, natural stone, mosaics, accent decos and much more, there is no end to what you can do with tile.`,
          },
          {
            icon: "layers",
            title: "Stone",
            desc: ` Natural stone tile is produced from natural materials that are quarried, slabbed, finished, and cut to size. Common types of stone used as flooring tile include granite, marble, limestone (including travertine), and slate.`,
          },
          {
            icon: "users",
            title: "Carpet",
            desc: `Carpet is a type of flooring that is made from woven fiber, and comes in a variety of styles, patterns, and colors. Due to its cushioned surface, carpet absorbs sound, adds additional warmth, and offers you a non-slip surface.`,
          },
          {
            icon: "monitor",
            title: "Wood Flooring",
            desc: `Wood floors add beauty to your home and are easy to maintain.  They come in many different species and finishes.`,
          },
        ],
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
